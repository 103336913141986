import auth from '##/services/auth';
import { FunctionalComponent, createContext } from 'preact';
import { useEffect, useState } from 'preact/hooks';

export type LoginState = 'loading' | 'success' | 'failed';

export const AuthContext = createContext<LoginState>('loading');

const AuthProvider: FunctionalComponent = ({ children }) => {
  const [loginState, setLoginState] = useState<LoginState>('loading');

  const authenticate = async () => {
    try {
      await auth.login();

      console.info('P+ login success');
      setLoginState('success');
    } catch (e) {
      console.error(`P+ login failed, reason: `, e);
      setLoginState('failed');
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <AuthContext.Provider value={loginState}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
