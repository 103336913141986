import { route } from 'preact-router';
import cls from 'classnames';
import { useEffect, useState } from 'preact/hooks';
import useAuth from '##/hooks/useAuth';
import useConfig from '##/hooks/useConfig';
import nav from '##/navigation';
import useKeyDownHandlers, {
  defaultKeyHandlers,
} from '##/hooks/useKeyDownHandlers';
import styles from './home.module.scss';
import { getNavId } from '##/utils';
import Button from '##/components/Button';
import useSettings from '##/hooks/useSettings';

const getAssetIdentifier = (idx: number) => `asset-${idx}`;

const MAX_LIST_LENGTH = 15;

const Home = () => {
  const loginState = useAuth();
  const { assets } = useConfig();
  const [currentFocus, setCurrentFocus] = useState('');
  const { openModal } = useSettings();

  useKeyDownHandlers(defaultKeyHandlers);

  useEffect(() => {
    if (!assets.length) {
      return;
    }

    nav.registerTree({
      id: 'home',
      orientation: 'vertical',
      children: [
        {
          id: 'settingsButton',
          isFocusable: true,
          onFocus: () => {
            setCurrentFocus('settingsButton');
          },
          onSelect: () => {
            setCurrentFocus('');
            openModal(true);
          },
        },
        {
          id: 'assetList',
          orientation: 'horizontal',
          children: [
            { id: 'leftColumn', orientation: 'vertical' },
            { id: 'rightColumn', orientation: 'vertical' },
          ],
        },
      ],
    });

    assets.forEach((item, index) => {
      nav.registerNode(getNavId(item.title, index), {
        isFocusable: true,
        index: index % MAX_LIST_LENGTH,
        parent: index < MAX_LIST_LENGTH ? 'leftColumn' : 'rightColumn',
        onFocus: () => {
          setCurrentFocus(getNavId(item.title, index));
        },
        onSelect: () => {
          route(`/player?selectedAssetIdx=${index}`);
        },
      });
    });

    nav.assignFocus('assetList');

    return () => {
      nav.unregisterNode('home');
    };
  }, [assets, openModal]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Select an asset to play
        <Button focused={currentFocus === 'settingsButton'}>Settings</Button>
      </div>
      {loginState !== 'loading' ? (
        <div className={styles.list}>
          {assets.map(({ title }, idx) => (
            <div
              className={cls(styles['list-item'], {
                [styles.focused]: currentFocus === getNavId(title, idx),
              })}
              key={getAssetIdentifier(idx)}
            >
              <div className={styles['item-content']}>
                {currentFocus === getNavId(title, idx) && (
                  <img
                    src="assets/icons/play-icon.svg"
                    className={styles.icon}
                  />
                )}
                {title}
              </div>
            </div>
          ))}
        </div>
      ) : (
        'Waiting for authentication'
      )}
    </div>
  );
};

export default Home;
