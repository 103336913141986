import {
  type StateUpdater,
  useCallback,
  useEffect,
  useState,
} from 'preact/hooks';
import cls from 'classnames';
import {
  PlaybackState,
  type VideoPlayerInterface,
} from '@cbsinteractive/avia-js';
import usePlayerControls from '##/hooks/usePlayerControls';
import nav from '##/navigation';
import type { SettingId } from '##/types';
import styles from './playerControls.module.scss';
import useSettings from '##/hooks/useSettings';

type PlayerControlsProps = {
  playbackState: string;
  isVisible: boolean;
  player?: VideoPlayerInterface;
  loadNextAsset?: () => void;
  loadPrevAsset?: () => void;
  setIsVisible: (isVisible: boolean) => void;
  clearLogs: () => void;
  openSettings: (opt: { id: SettingId; text: string }) => void;
  setAutoTest: StateUpdater<boolean>;
  isAutoTest: boolean;
  className?: string;
};

const actionButtons = [
  {
    id: 'top_row',
    buttons: [
      {
        id: 'rw',
        text: () => 'Rewind',
        icon: { default: 'assets/icons/rw-icon.svg' },
      },
      {
        id: 'play_pause',
        text: () => 'Play/Pause',
        icon: {
          [PlaybackState.PAUSED as string]: 'assets/icons/pause-icon.svg',
          default: 'assets/icons/play-icon.svg',
        },
      },
      {
        id: 'ff',
        text: () => 'Fast Forward',
        isSuffixIcon: true,
        icon: { default: 'assets/icons/ff-icon.svg' },
      },
      {
        id: 'tune_down',
        text: () => 'Prev Asset',
        icon: { default: 'assets/icons/prev-icon.svg' },
      },
      {
        id: 'tune_up',
        text: () => 'Next Asset',
        isSuffixIcon: true,
        icon: { default: 'assets/icons/next-icon.svg' },
      },
    ],
  },
  {
    id: 'bottom_row',
    buttons: [
      { id: 'hide', text: () => 'Hide' },
      {
        id: 'automatic_test',
        text: ({ isAutoTest }: { isAutoTest?: boolean } = {}) =>
          `${isAutoTest ? 'Stop' : 'Run'} Automatic Test`,
      },
      { id: 'clear', text: () => 'Clear logs' },
      {
        id: 'settings_modal',
        text: () => 'Settings',
      },
      {
        id: 'drm_options',
        text: () => 'DRM Options',
      },
      {
        id: 'quality',
        text: () => 'Quality',
      },
      {
        id: 'audio',
        text: () => 'Audio',
      },
      { id: 'subtitles', text: () => 'Subtitles' },
    ],
  },
];

let previousButtonFocus = 'player';

const PlayerControls = ({
  playbackState,
  isVisible,
  player,
  loadNextAsset,
  loadPrevAsset,
  setIsVisible,
  clearLogs,
  className,
  openSettings,
  setAutoTest,
  isAutoTest,
}: PlayerControlsProps) => {
  const [currentFocus, setCurrentFocus] = useState<string | null>(null);
  const { playPause, fastForward, rewind } = usePlayerControls(player);
  const { openModal } = useSettings();

  const onButtonClick = useCallback(
    ({ id, text }: { id: string; text: string }) => {
      previousButtonFocus = id;

      switch (id) {
        case 'rw':
          rewind();
          break;
        case 'ff':
          fastForward();
          break;
        case 'play_pause':
          playPause();
          break;
        case 'tune_up':
          loadNextAsset?.();
          break;
        case 'tune_down':
          loadPrevAsset?.();
          break;
        case 'hide':
          setIsVisible(false);
          break;
        case 'clear':
          clearLogs();
          break;
        case 'automatic_test':
          setAutoTest((val) => !val);
          break;
        case 'settings_modal':
          openModal(true);
          break;
        case 'quality':
        case 'audio':
        case 'subtitles':
        case 'protection_type':
        case 'drm_options':
          openSettings({ id, text });
          break;
        default:
          break;
      }
    },
    [
      rewind,
      playPause,
      fastForward,
      loadNextAsset,
      loadPrevAsset,
      setIsVisible,
      clearLogs,
      openSettings,
      setAutoTest,
      openModal,
    ],
  );

  const isButtonDisabled = useCallback(
    (id: string) => {
      return isAutoTest && id !== 'automatic_test';
    },
    [isAutoTest],
  );

  useEffect(() => {
    nav.registerTree({
      id: 'player',
      orientation: 'vertical',
      isIndexAlign: true,
      onInactive: () => {
        setCurrentFocus(null);
      },
      children: actionButtons.map((row) => ({
        id: row.id,
        orientation: 'horizontal',
      })),
    });

    actionButtons.forEach((row) => {
      row.buttons.forEach((button, index) => {
        if (!isButtonDisabled(button.id)) {
          nav.registerNode(button.id, {
            parent: row.id,
            isFocusable: true,
            index,
            onSelect: () => {
              onButtonClick({ id: button.id, text: button.text() });
            },
            onFocus: () => {
              setCurrentFocus(button.id);
            },
          });
        }
      });
    });

    nav.assignFocus(previousButtonFocus);

    return () => {
      nav.unregisterNode('player');
    };
  }, [onButtonClick, isButtonDisabled]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cls(styles.container, className)}>
      {actionButtons.map((row) => (
        <div className={styles.row} key={row.id}>
          {row.buttons.map((btn) => (
            <div
              key={btn.id}
              className={cls(styles.button, {
                [styles.focused]: currentFocus === btn.id,
                [styles.disabled]: isButtonDisabled(btn.id),
              })}
            >
              {'icon' in btn && (
                <img
                  src={
                    (btn.icon as Record<string, string>)[playbackState] ??
                    btn.icon.default
                  }
                  className={cls(styles.icon, {
                    [styles['suffix-icon']]: btn.isSuffixIcon,
                  })}
                />
              )}
              {btn.text({ isAutoTest })}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PlayerControls;
