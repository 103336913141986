import { StateUpdater, useEffect, useState } from 'preact/hooks';
import {
  ErrorEventInterface,
  PlayerEvent,
  VideoPlayerInterface,
} from '@cbsinteractive/avia-js';

type UseLogsReturn = [
  logs: string[],
  setLogs: StateUpdater<string[]>
]

const useLogs = (
  player: VideoPlayerInterface | undefined,
): UseLogsReturn => {
  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const handleError = (error: ErrorEventInterface): void => {
      const {
        detail: {
          error: { message, code },
        },
      } = error;
      const timestamp = (new Date()).toTimeString().split(' ')[0];
      const text = `[${timestamp}] ${code} 
        ${message}`;

      console.error(error, logs);

      setLogs((prevLogs) => {
        if (prevLogs.length > 9) {
          return [...prevLogs.slice(1), text];
        }

        return [...prevLogs, text];
      });
    };

    player.on(PlayerEvent.ERROR, handleError);

    return () => {
      player.off(PlayerEvent.ERROR, handleError);
    }
  });

  return [logs, setLogs];
};

export default useLogs;
