import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'preact/hooks';
import {
  Autoplay,
  KeyboardScope,
  LogLevel,
  PerformanceMode,
  type PlayerOptionsInterface,
  type VideoPlayerInterface,
  PlayerEvent,
  type AudioTrackInterface,
  type AudioTrackChangeEventInterface,
  type QualityInterface,
  type TextTrackInterface,
  type QualityChangeEventInterface,
  type TextTrackChangeEventInterface,
} from '@cbsinteractive/avia-js';

import useLogs from '##/hooks/useLogs';
import useKeyDownHandlers, {
  defaultKeyHandlers,
} from '##/hooks/useKeyDownHandlers';
import usePlayerControls from '##/hooks/usePlayerControls';
import usePlayerResource from '##/hooks/usePlayerResource';
import usePlayerEvents from '##/hooks/usePlaybackState';
import { Avia } from '##/lib/Avia';
import type { SettingId } from '##/types';

import PlayerControls from '../PlayerControls';
import PlayerMetrics from '../PlayerMetrics';
import SettingsList from '../SettingsList';
import TestRunner from '../TestRunner';

import styles from './player.module.scss';
import { vtg } from '##/services/analytics';

export type PlayerActions = {
  loadNextAsset: () => void;
  loadPrevAsset: () => void;
  rewind: () => void;
  fastForward: () => void;
  playPause: () => void;
  setProtectionType: (protectionType: string) => void;
};

type RobustnessLevel =
  | 'SW_SECURE_CRYPTO' // Widevine L3
  | 'SW_SECURE_DECODE' // Widevine L3
  | 'HW_SECURE_CRYPTO' // Widevine L2
  | 'HW_SECURE_DECODE' // Widevine L1
  | 'HW_SECURE_ALL'; // Widevine L1

export type DRMOptions = Partial<{
  videoRobustness: RobustnessLevel;
  audioRobustness: RobustnessLevel;
}>;

const Player = () => {
  const INITIAL_PLAYER_OPTIONS: PlayerOptionsInterface = {
    logLevel: LogLevel.DEBUG,
    autoplay: Autoplay.ATTEMPT_UNMUTED_THEN_MUTED,
    container: '#player-container',
    keyboardScope: KeyboardScope.NONE,
    performanceMode: PerformanceMode.TV,
    overrides: {
      deferAutoplayDetection: true,
    },
    reuseVideoElement: false,
    renderTextTrackNatively: false,
    plugins: [
      vtg().ts.AviaTracking.plugin({
        config: vtg().config,
      }),
    ],
  };

  const [isControlsVisible, setIsControlsVisible] = useState<boolean>(true);
  const [drmOptions, setDrmOptions] = useState<DRMOptions>({});
  const [protectionType, setProtectionType] = useState<
    'widevine' | 'playready'
  >('widevine');
  const { resource, resourceTitle, loadNextAsset, loadPrevAsset } =
    usePlayerResource(drmOptions);
  const [isAutoTest, setIsAutoTest] = useState(false);
  const [player, setPlayer] = useState<VideoPlayerInterface>();
  const [audioTrack, setAudioTrack] = useState<AudioTrackInterface>();
  const [quality, setQuality] = useState<QualityInterface>();
  const [subtitles, setSubtitles] = useState<TextTrackInterface>();
  const [selectedSetting, setSelectedSetting] = useState<{
    id: SettingId;
    text: string;
  } | null>(null);
  const options = useRef<PlayerOptionsInterface>(INITIAL_PLAYER_OPTIONS);
  const { playbackState } = usePlayerEvents(player);
  const { fastForward, rewind } = usePlayerControls(player);
  const [logs, setLogs] = useLogs(player);

  const loadNextPlayerAsset = useCallback(() => {
    player?.stop();
    loadNextAsset();
  }, [player, loadNextAsset]);

  const loadPrevPlayerAsset = useCallback(() => {
    player?.stop();
    loadPrevAsset();
  }, [player, loadPrevAsset]);

  const keyHandlers = useMemo(
    () => ({
      up: !isControlsVisible ? loadPrevPlayerAsset : defaultKeyHandlers.up,
      down: !isControlsVisible ? loadNextPlayerAsset : defaultKeyHandlers.down,
      right: !isControlsVisible ? fastForward : defaultKeyHandlers.right,
      left: () => {
        if (!isControlsVisible) {
          rewind();
        } else if (selectedSetting) {
          setSelectedSetting(null);
        } else {
          defaultKeyHandlers.left();
        }
      },
      ok: () =>
        !isControlsVisible
          ? setIsControlsVisible(true)
          : defaultKeyHandlers.ok(),
      back: () =>
        selectedSetting ? setSelectedSetting(null) : defaultKeyHandlers.back(),
    }),
    [
      isControlsVisible,
      selectedSetting,
      fastForward,
      loadNextPlayerAsset,
      loadPrevPlayerAsset,
      rewind,
    ],
  );

  useKeyDownHandlers(keyHandlers);

  useEffect(() => {
    if (player) {
      const onAudioTrackChange = (event: AudioTrackChangeEventInterface) => {
        setAudioTrack(event.detail.audioTrack);
      };
      const onQualityChange = (event: QualityChangeEventInterface) => {
        setQuality(event.detail.quality);
      };
      const onSubtitleChange = (event: TextTrackChangeEventInterface) => {
        setSubtitles(event.detail.textTrack);
      };

      player.on(PlayerEvent.AUDIO_TRACK_CHANGE, onAudioTrackChange);
      player.on(PlayerEvent.QUALITY_CHANGE, onQualityChange);
      player.on(PlayerEvent.TEXT_TRACK_CHANGE, onSubtitleChange);

      return () => {
        player.off(PlayerEvent.AUDIO_TRACK_CHANGE, onAudioTrackChange);
        player.off(PlayerEvent.QUALITY_CHANGE, onQualityChange);
        player.off(PlayerEvent.TEXT_TRACK_CHANGE, onSubtitleChange);
      };
    }
  }, [player]);

  const handlePlayerChange = useCallback(
    (player: VideoPlayerInterface) => {
      if (resource) {
        setPlayer(player);

        window.player = player;
      }
    },
    [resource],
  );

  const openSettings = useCallback(
    (opt: { id: SettingId; text: string }) => setSelectedSetting(opt),
    [],
  );

  const clearLogs = useCallback(() => setLogs([]), [setLogs]);

  return (
    <div className={styles.container}>
      <div className={styles['title-container']}>
        <div className={styles.title}>{resourceTitle}</div>
        <div className={styles.state}>{playbackState}</div>
      </div>
      {resource && (
        <Avia
          id="player"
          className="player"
          options={options.current}
          resource={resource}
          onPlayerChange={handlePlayerChange}
          debug
        />
      )}
      <PlayerMetrics
        isVisible={isControlsVisible}
        className={styles.metrics}
        audioTrack={audioTrack}
        quality={quality}
        subtitle={subtitles}
        logs={logs}
      />
      {player && (
        <PlayerControls
          className={styles.controls}
          playbackState={playbackState}
          isVisible={isControlsVisible}
          player={player}
          loadNextAsset={loadNextPlayerAsset}
          loadPrevAsset={loadPrevPlayerAsset}
          setIsVisible={setIsControlsVisible}
          openSettings={openSettings}
          clearLogs={clearLogs}
          setAutoTest={setIsAutoTest}
          isAutoTest={isAutoTest}
        />
      )}
      {selectedSetting && (
        <SettingsList
          setting={selectedSetting}
          player={player}
          setDrmOptions={setDrmOptions}
          drmOptions={drmOptions}
          setDrmType={setProtectionType}
          drmType={protectionType}
        />
      )}
      {isAutoTest && player && (
        <TestRunner
          player={player}
          loadNext={loadNextPlayerAsset}
          loadPrev={loadPrevPlayerAsset}
        />
      )}
    </div>
  );
};

export default Player;
