import SettingsModal from '##/components/SettingsModal/SettingsModal';
import { FunctionalComponent, createContext } from 'preact';
import { Reducer, useMemo, useReducer, useState } from 'preact/hooks';

interface SettingState {
  preferredAudioCodec: string[];
  preferredVideoCodec: string[];
  drmSystem: 'widevine' | 'playready';
}

export type SettingStateKeys = keyof SettingState;

interface SetAction {
  type: 'SET_ACTION';
  updates: {
    key: SettingStateKeys;
    value: SettingState[SettingStateKeys];
  }[];
}

const settingReducer: Reducer<SettingState, SetAction> = (state, action) => {
  switch (action.type) {
    case 'SET_ACTION':
      return {
        ...state,
        ...action.updates.reduce<Record<string, unknown | unknown[]>>(
          (acc, item) => {
            acc[item.key] = item.value;

            return acc;
          },
          {},
        ),
      };
    default:
      return state;
  }
};

const useProviderValue = () => {
  const [isOpen, setOpen] = useState(false);
  const [settings, dispatch] = useReducer(settingReducer, {
    preferredVideoCodec: [],
    preferredAudioCodec: [],
    drmSystem: 'widevine',
  });

  return useMemo(
    () => ({
      settings,
      isOpen,
      dispatch,
      openModal: setOpen,
    }),
    [settings, isOpen],
  );
};

export type SettingProviderValue = ReturnType<typeof useProviderValue>;

export const SettingContext = createContext<SettingProviderValue | null>(null);

const SettingProvider: FunctionalComponent = ({ children }) => {
  const value = useProviderValue();

  return (
    <SettingContext.Provider value={value}>
      {children}
      <SettingsModal isOpen={value.isOpen} />
    </SettingContext.Provider>
  );
};

export default SettingProvider;
