import type {
  AudioTrackInterface,
  ResourceConfigurationInterface,
  QualityInterface,
  TextTrackInterface,
} from '@cbsinteractive/avia-js';
import type { StateUpdater } from 'preact/hooks';

export type Maybe<T> = T | undefined | null;

export type PlayerResourceSelector = {
  resource?: ResourceConfigurationInterface;
  resourceTitle: string;
  loadNextAsset: () => void;
  loadPrevAsset: () => void;
  setSelectedAssetIdx: StateUpdater<number>;
};

export type DRMConfig = {
  widevine?: {
    url: string;
    header: Record<string, string>;
  };
  playready?: {
    url: string;
    header: Record<string, string>;
  };
  aes?: {
    provider: string;
    header: Record<string, string>;
  };
};

export type Asset = {
  title: string;
  mediaUrl: string;
  id?: string;
  drm?: DRMConfig;
  isLive?: boolean;
  useDai?: boolean;
};

export type PlayerControls = {
  playPause: () => void;
  fastForward: () => void;
  rewind: () => void;
  changeQuality: (q: Maybe<QualityInterface>) => void;
  changeAudio: (a: Maybe<AudioTrackInterface>) => void;
  changeSubtitle: (s: Maybe<TextTrackInterface>) => void;
  getQuality: () => Maybe<QualityInterface>;
  getQualities: () => Maybe<QualityInterface[]>;
  getAudio: () => Maybe<AudioTrackInterface>;
  getAudios: () => Maybe<AudioTrackInterface[]>;
  getSubtitle: () => Maybe<TextTrackInterface>;
  getSubtitles: () => Maybe<TextTrackInterface[]>;
};

export const enum TestActionId {
  PLAY,
  PAUSE,
  RW,
  FF,
  PREV,
  NEXT,
  Q,
  SUB,
  A,
}

export const enum TestStatus {
  SUCCESS,
  PENDING,
  FAILURE,
  WARNING,
}

export enum TestIcons {
  SUCCESS = '\u2713',
  FAILURE = '\u2715',
  WARNING = '\u26A0',
}

export type TestColors = { [k in TestStatus]: string };

export type TestStep = {
  action: TestActionId;
  title: string;
  logo: string;
  TestStatus: TestStatus;
};

export type SettingId =
  | 'audio'
  | 'subtitles'
  | 'protection_type'
  | 'quality'
  | 'drm_options';
