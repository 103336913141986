import type { NodeConfig } from 'lrud';
import cls from 'classnames';
import { createPortal, useEffect, useRef, useState } from 'preact/compat';
import styles from './settingsModal.module.scss';
import { getNavId, isArrayEqual } from '##/utils';
import nav from '##/navigation';
import Button from '../Button';
import useSettings from '##/hooks/useSettings';
import type { SettingStateKeys } from '##/contexts/settingContext';

const availableSettings = [
  {
    header: 'Preferred video codec:',
    key: 'preferredVideoCodec' as const,
    options: [
      {
        name: 'none',
        value: [],
      },
      {
        name: 'avc',
        value: ['avc'],
      },
      {
        name: 'hev/hvc',
        value: ['hev', 'hvc'],
      },
      {
        name: 'dvhe',
        value: ['dvhe'],
      },
    ],
  },
  {
    header: 'Preferred Audio codec:',
    key: 'preferredAudioCodec' as const,
    options: [
      {
        name: 'none',
        value: [],
      },
      {
        name: 'mp4a',
        value: ['mp4a'],
      },
      {
        name: 'ec-3',
        value: ['ec-3', 'ac-3'],
      },
    ],
  },
  {
    header: 'DRM:',
    key: 'drmSystem' as const,
    options: [
      {
        name: 'Widevine',
        value: 'widevine' as const,
      },
      {
        name: 'Playready',
        value: 'playready' as const,
      },
    ],
  },
];

interface Props {
  isOpen: boolean;
}

type updateValue =
  (typeof availableSettings)[number]['options'][number]['value'];

const SettingsModal = ({ isOpen }: Props) => {
  const [focus, setFocus] = useState('');
  const [, forceUpdate] = useState({});
  const { openModal, dispatch, settings } = useSettings();
  const localSettings = useRef(settings);
  const updates = useRef<{ key: SettingStateKeys; value: updateValue }[]>([]);

  useEffect(() => {
    if (isOpen) {
      const lastFocus = nav.getCurrentFocusNode() ?? '';

      nav.registerTree({
        id: 'modal',
        orientation: undefined,
        children: [
          {
            id: 'modalContent',
            orientation: 'vertical',
            children: [
              ...availableSettings.map<NodeConfig>((setting) => ({
                id: setting.key,
                orientation: 'horizontal',
                children: setting.options.map((option, index) => ({
                  id: getNavId(setting.key, option.name),
                  isFocusable: true,
                  index,
                  onFocus: () => {
                    setFocus(getNavId(setting.key, option.name));
                  },
                  onSelect: () => {
                    const val = {
                      key: setting.key,
                      value: option.value,
                    };
                    const idx = updates.current.findIndex(
                      (u) => u.key === setting.key,
                    );

                    if (idx > -1) {
                      updates.current[idx] = val;
                    } else {
                      updates.current.push(val);
                    }

                    // @ts-ignore
                    localSettings.current[setting.key] = option.value;
                    forceUpdate({});
                  },
                })),
              })),
              {
                id: 'closeButton',
                isFocusable: true,
                onSelect: () => {
                  openModal(false);
                  dispatch({
                    type: 'SET_ACTION',
                    updates: updates.current,
                  });
                },
                onFocus: () => setFocus('closeButton'),
              },
            ],
          },
        ],
      });

      nav.assignFocus('modalContent');

      return () => {
        nav.assignFocus(lastFocus);
        nav.unregisterNode('modal');
      };
    }
  }, [isOpen, openModal, dispatch]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={styles.container}>
      {availableSettings.map((setting) => (
        <div key={setting.key} className={styles.setting}>
          {setting.header}
          <div className={styles['option-list']}>
            {setting.options.map((option) => (
              <div
                key={option.name}
                title={option.name}
                className={cls(styles.option, {
                  [styles.focused]:
                    focus === getNavId(setting.key, option.name),
                  [styles.selected]: isArrayEqual(
                    localSettings.current[setting.key],
                    option.value,
                  ),
                })}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      ))}
      <Button focused={focus === 'closeButton'} className={styles.close}>
        Close
      </Button>
    </div>,
    document.getElementById('modal') as HTMLDivElement,
  );
};

export default SettingsModal;
