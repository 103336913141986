import onKeyDown, {
  KEYDOWN_EVENT,
  KeyEventHandlers,
} from '##/services/keyHandler';
import { useEffect } from 'preact/hooks';
import nav from '##/navigation';

export const defaultKeyHandlers = {
  up: () => nav.handleKeyEvent({ direction: 'up' }),
  down: () => nav.handleKeyEvent({ direction: 'down' }),
  left: () => nav.handleKeyEvent({ direction: 'left' }),
  right: () => nav.handleKeyEvent({ direction: 'right' }),
  ok: () => nav.handleKeyEvent({ direction: 'enter' }),
  back: () => window.history.back(),
};

const useKeyDownHandlers = (keyEventHandlers: KeyEventHandlers): void => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) =>
      onKeyDown(event, {
        up: keyEventHandlers?.up,
        down: keyEventHandlers?.down,
        left: keyEventHandlers?.left,
        right: keyEventHandlers?.right,
        ok: keyEventHandlers?.ok,
        back: keyEventHandlers?.back,
      });

    window.addEventListener(KEYDOWN_EVENT, keyDownHandler);

    return () => {
      window.removeEventListener(KEYDOWN_EVENT, keyDownHandler);
    };
  }, [
    keyEventHandlers?.back,
    keyEventHandlers?.down,
    keyEventHandlers?.left,
    keyEventHandlers?.ok,
    keyEventHandlers?.right,
    keyEventHandlers?.up,
  ]);
};

export default useKeyDownHandlers;
