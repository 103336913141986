import type { ResourceConfigurationInterface } from '@cbsinteractive/avia-js';
import { useEffect, useState, useCallback } from 'preact/compat';

import Auth, { DRM_URLS } from '##/services/auth';
import type { Asset, PlayerResourceSelector } from '##/types';
import useConfig from './useConfig';
// @ts-ignore
import { analyticsMock } from '##/services/analytics';
import type { DRMOptions } from '##/components/Player/Player';
import useSettings from './useSettings';

const getDrm = async (
  asset: Asset,
  protectionType: 'widevine' | 'playready',
  drmOptions: DRMOptions,
): Promise<Record<string, any> | undefined> => {
  try {
    const protType = asset?.isLive ? 'aes' : protectionType;

    if (asset?.isLive) {
      const { ls_session } = await Auth.getSessionTokenBody();

      return {
        aes: {
          header: {
            Authorization: `Bearer ${ls_session}`,
          },
          provider: DRM_URLS.aes,
        },
      };
    }

    if (asset.id) {
      // Has id => P+ asset => acquire DRM automatically
      const drmUrl = await Auth.getDrmConfig(asset.id, protectionType);

      return {
        enabled: true,
        [protType]: {
          url: drmUrl,
          ...drmOptions,
        },
      };
    }

    if (asset.drm) {
      // Has manually added DRM header
      asset.drm = {
        ...asset.drm,
        ...drmOptions,
      };

      return {
        ...asset.drm,
        enabled: true,
      };
    }

    // No DRM
    return undefined;
  } catch (e) {
    console.error(e);

    return undefined;
  }
};

const usePlayerResource = (drmOptions: DRMOptions): PlayerResourceSelector => {
  const initialAssetIdx = Number(
    new URLSearchParams(window.location.search).get('selectedAssetIdx'),
  );
  const { assets } = useConfig();
  const [selectedAssetIdx, setSelectedAssetIdx] =
    useState<number>(initialAssetIdx);
  const [resource, setResource] = useState<
    ResourceConfigurationInterface | undefined
  >(undefined);
  const { settings } = useSettings();

  const loadNextAsset = useCallback(() => {
    if (selectedAssetIdx + 1 === assets?.length) {
      setSelectedAssetIdx(0);
    }

    setSelectedAssetIdx((prevAssetIdx) => prevAssetIdx + 1);
  }, [assets?.length, selectedAssetIdx]);

  const loadPrevAsset = useCallback(() => {
    if (selectedAssetIdx === 0) {
      setSelectedAssetIdx(assets?.length - 1);
    }

    setSelectedAssetIdx((prevAssetIdx) => prevAssetIdx - 1);
  }, [assets?.length, selectedAssetIdx]);

  const getPlayerResource = useCallback(async (): Promise<void> => {
    const asset = assets?.[selectedAssetIdx];
    const drm = await getDrm(asset, settings.drmSystem, drmOptions);
    const adConfig = {
      ad: {
        ssai: {
          adParameters: {
            imafw_csid: 'streaming_paramountplus_uk_ctv_skystb_vod',
          },
          contentSourceId: 2497752,
          videoId: asset.id,
          isLive: asset.isLive,
          format: asset.isLive ? 'hls' : 'dash',
        },
      },
    };

    console.info(drm);
    console.info(
      `Loading new resource with index: ${selectedAssetIdx}, ${JSON.stringify(
        asset,
      )}`,
    );

    const playerResource = {
      ...(asset.useDai ? adConfig : {}),
      location: {
        drm,
        mediaUrl: asset.mediaUrl,
      },
      playback: {
        abr: {
          capQualityToScreenSize: false,
        },
        adapter: {
          shaka: {
            config: {
              streaming: {
                // needed for STB6, has no effect on browsers other than newer WebKit
                useNativeHlsOnSafari: false,
              },
              preferredVideoCodecs: settings.preferredVideoCodec,
              preferredAudioCodecs: settings.preferredAudioCodec,
            },
          },
        },
      },
      metadata: {
        ...analyticsMock,
        mediaUrl: asset.mediaUrl,
        mediaId: asset.id || 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        isLive: asset?.isLive || false,
        videoTitle: asset.title,
        showSeriesTitle: asset.title,
        showTitle: asset.title,
        showEpisodeTitle: asset.title,
      },
    } as ResourceConfigurationInterface;

    console.info(playerResource);

    setResource(playerResource);
  }, [
    drmOptions,
    assets,
    selectedAssetIdx,
    settings.preferredVideoCodec,
    settings.preferredAudioCodec,
    settings.drmSystem,
  ]);

  useEffect(() => {
    getPlayerResource();
  }, [getPlayerResource]);

  return {
    resource,
    resourceTitle: assets?.[selectedAssetIdx].title,
    loadNextAsset,
    loadPrevAsset,
    setSelectedAssetIdx,
  };
};

export default usePlayerResource;
