import {
  PlayerControls,
  TestActionId,
  TestIcons,
  TestStatus,
  TestStep,
} from '##/types';
import { useEffect, useState, useRef, useCallback } from 'preact/hooks';
import usePlayerControls from './usePlayerControls';
import {
  ErrorEventInterface,
  PlayerEvent,
  VideoPlayerInterface,
} from '@cbsinteractive/avia-js';

const sleep = (sec: number) =>
  new Promise((res) => setTimeout(res, sec * 1000));

const switchToRandomQuality = (controls: PlayerControls) => {
  const firstNotCurrentQuality = controls
    .getQualities()
    ?.find((quality) => quality.index !== controls.getQuality()?.index);

  controls.changeQuality(firstNotCurrentQuality);
};

const switchToRandomSubtitle = (controls: PlayerControls) => {
  const firstNotCurrentSub = controls
    .getSubtitles()
    ?.find((sub) => sub.id !== controls.getSubtitle()?.id);

  controls.changeSubtitle(firstNotCurrentSub);
};

const switchToRandomAudio = (controls: PlayerControls) => {
  const firstNotCurrentAudio = controls
    .getAudios()
    ?.find((audio) => audio.id !== controls.getAudio()?.id);

  controls.changeAudio(firstNotCurrentAudio);
};

const getTestRunner = (
  controls: PlayerControls,
  action: TestActionId,
  prevAsset: () => void,
  nextAsset: () => void,
) => {
  const handlers: {
    [key in TestActionId]: () => void;
  } = {
    [TestActionId.PLAY]: () => controls.playPause(),
    [TestActionId.PAUSE]: () => controls.playPause(),
    [TestActionId.RW]: () => controls.rewind(),
    [TestActionId.FF]: () => controls.fastForward(),
    [TestActionId.PREV]: prevAsset,
    [TestActionId.NEXT]: nextAsset,
    [TestActionId.Q]: () => switchToRandomQuality(controls),
    [TestActionId.SUB]: () => switchToRandomSubtitle(controls),
    [TestActionId.A]: () => switchToRandomAudio(controls),
  };

  return () => handlers[action]?.();
};

const useTestScheduler = (
  steps: TestStep[],
  player: VideoPlayerInterface,
  loadNext: () => void,
  loadPrev: () => void,
) => {
  const error = useRef<ErrorEventInterface | null>(null);
  const warning = useRef<Error | null>(null);
  const [currentTestIndex, setCurrentTestIndex] = useState(0);
  const [title, setTitle] = useState('Initializing Test automation ...');
  const controls = usePlayerControls(player);
  const controlsRef = useRef(controls);
  controlsRef.current = controls;
  const loadPrevRef = useRef(loadPrev);
  const loadNextRef = useRef(loadNext);
  const [updatedSteps, setUpdatedSteps] = useState(steps);

  const runTask = useCallback(async () => {
    setTitle(
      `Running Test ${currentTestIndex + 1}: ${
        updatedSteps[currentTestIndex].title
      }`,
    );

    await sleep(2);
    try {
      const runner = getTestRunner(
        controlsRef.current,
        updatedSteps[currentTestIndex]?.action,
        loadPrevRef.current,
        loadNextRef.current,
      );

      runner();
    } catch (e) {
      // warning means the player is running but some error happened
      console.warn('Warning during Automation test: ', e);
      warning.current = new Error(e as string);
    }
    await sleep(6);

    return [error.current, warning.current];
  }, [updatedSteps, currentTestIndex]);

  const updateList = useCallback(
    (logo: string, status: TestStatus, index: number) => {
      setUpdatedSteps((updatedSteps) =>
        updatedSteps.map((step, i) =>
          i === index
            ? {
                ...step,
                logo,
                TestStatus: status,
              }
            : step,
        ),
      );
    },
    [],
  );

  useEffect(() => {
    async function start() {
      const [testError, testWarning] = await runTask();

      if (testError) {
        updateList(TestIcons.FAILURE, TestStatus.FAILURE, currentTestIndex);
        setTitle((title) => `${title} Failed. Check the console for details`);
        error.current = null;
        return;
      }

      if (testWarning) {
        updateList(TestIcons.WARNING, TestStatus.WARNING, currentTestIndex);
        setTitle(
          (title) => `${title} Raised a Warning. Check the console for details`,
        );
        warning.current = null;
      } else {
        updateList(TestIcons.SUCCESS, TestStatus.SUCCESS, currentTestIndex);
      }

      setCurrentTestIndex((currentTestIndex) => currentTestIndex + 1);
    }

    if (currentTestIndex < updatedSteps.length) {
      start();
    }
  }, [runTask, updateList, updatedSteps, currentTestIndex]);

  useEffect(() => {
    const handleError = (err: ErrorEventInterface) => (error.current = err);

    player.on(PlayerEvent.ERROR, handleError);

    return () => {
      player.off(PlayerEvent.ERROR, handleError);
    };
  }, [player]);

  useEffect(() => {
    if (currentTestIndex >= updatedSteps.length) {
      setTitle(`Done: ${updatedSteps.length}/${currentTestIndex} passed`);
    }
  }, [currentTestIndex, updatedSteps.length]);

  return { updatedSteps, title };
};

export default useTestScheduler;
