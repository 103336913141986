import { Router, Route } from 'preact-router';

import Home from '##/pages/Home';
import Player from '##/pages/Player';
import AuthProvider from './contexts/authContext';
import SettingProvider from './contexts/settingContext';

const App = () => {
  return (
    <div id="app">
      <main>
        <SettingProvider>
          <AuthProvider>
            <Router>
              <Route path="/" component={Home} />
              <Route path="/player" component={Player} />
            </Router>
          </AuthProvider>
        </SettingProvider>
      </main>
      <div id="modal" />
    </div>
  );
};

export default App;
