import Player from '##/components/Player/Player';

const style = {
  container: {
    width: '100vw',
    height: '100vh',
  },
};

const PlayerPage = () => {
  return (
    <div style={style.container}>
      <Player />
    </div>
  );
};

export default PlayerPage;
