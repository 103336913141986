import useTestScheduler from '##/hooks/useTestScheduler';
import { TestActionId, TestColors, TestStatus, TestStep } from '##/types';
import type { VideoPlayerInterface } from '@cbsinteractive/avia-js';
import styles from './testRunner.module.scss';

const testSteps: TestStep[] = [
  {
    action: TestActionId.PAUSE,
    title: 'pause',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.PLAY,
    title: 'play',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.FF,
    title: 'seek: fast forward',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.RW,
    title: 'seek: rewind',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.Q,
    title: 'change quality',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },

  {
    action: TestActionId.A,
    title: 'change audio',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.SUB,
    title: 'change subtitle',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.PREV,
    title: 'previous episode',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
  {
    action: TestActionId.NEXT,
    title: 'Next episode',
    logo: '',
    TestStatus: TestStatus.PENDING,
  },
];

const colors: TestColors = {
  [TestStatus.SUCCESS]: styles.success,
  [TestStatus.PENDING]: styles.pending,
  [TestStatus.FAILURE]: styles.failure,
  [TestStatus.WARNING]: styles.warning,
};

function TestRunner({
  player,
  loadNext,
  loadPrev,
}: {
  player: VideoPlayerInterface;
  loadNext: () => void;
  loadPrev: () => void;
}) {
  const { title, updatedSteps } = useTestScheduler(
    testSteps,
    player,
    loadNext,
    loadPrev,
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.list}>
        {updatedSteps.map((step, i) => (
          <div className={colors[step.TestStatus]} key={step.title + i}>
            {i + 1}. {step.logo} {step.title}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestRunner;
