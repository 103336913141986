import cls from 'classnames';
import type { FunctionalComponent } from 'preact';
import styles from './button.module.scss';

interface Props {
  focused: boolean;
  className?: string;
}

const Button: FunctionalComponent<Props> = ({
  focused,
  children,
  className,
}) => {
  return (
    <div
      className={cls(styles.button, className, { [styles.focused]: focused })}
    >
      {children}
    </div>
  );
};

export default Button;
