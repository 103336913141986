/* eslint-disable quote-props */
/* eslint-disable max-len */

export const vtg = (): any => (window as any).vtg;

export const analyticsMock = {
  visitorId: '',
  playhead: 0,
  adPlayhead: 0,
  utcPlayhead: null,
  elapsedTime: -1,
  timestamp: 1676984906973,
  isAd: false,
  playheadMs: 0,
  utcSeconds: 1676984907,
  playerInfo: {
    isPaused: true,
    isAutoplay: false,
    hasPreload: false,
    width: -1,
    height: -1,
    srcWidth: -1,
    srcHeight: -1,
    srcDuration: 3400,
    srcBitrate: 5700717,
    isFullscreen: false,
    srcUrl: '',
    posterUrl: '',
    language: 'en-GB',
    volume: 1,
    muted: false,
  },
  platform: 'Sky',
  version: 'UNKNOWN',
  timeToStart: 2221,
  mediaUrl:
    'UNKNOWN',
  mediaId: 'UNKNOWN',
  duration: 3400,
  bitrate: 5700717,
  isLive: false,
  isDcrLive: false,
  isEpisode: false,
  streamType: 'VOD',
  hasSessionResumed: false,
  genre: 'UNKNOWN',
  status: 'AVAILABLE',
  _firstIngestDate: '11/30/22 06:40 EST',
  expired: false,
  showPageUrl: 'UNKNOWN',
  cbsShowId: 61457230,
  primaryCategory: '1991811139703',
  primaryCategoryName: 'UNKNOWN',
  editDate: 1669808449000,
  _editDate: 'UNKNOWN',
  _lastModifiedDate: 'UNKNOWN',
  ingestDate: 1669808454000,
  airDate: 1636938000000,
  description:
    'UNKNOWN',
  shortDescription:
    'UNKNOWN',
  pubDate: 1669808454000,
  label: 'UNKNOWN',
  videoPageUrl:
    'UNKNOWN',
  url: 'UNKNOWN',
  assetType: 'UNKNOWN',
  firstIngestDate: 1669808454000,
  topLevelCategory: 'UNKNOWN',
  fullEpisode: true,
  exclusive: false,
  contentId: 'UNKNOWN',
  title: 'UNKNOWN',
  _ingestDate: '11/30/22 06:40 EST',
  episodeNum: '1',
  _pubDate: '11/30/22 06:40 EST',
  seasonNum: '1',
  brand: 'UNKNOWN',
  pid: 'UNKNOWN',
  seriesTitle: 'UNKNOWN',
  _airDate: '11/14/21 20:00 EST',
  lastModifiedDate: 1675031960000,
  rating: 'UNKNOWN',
  deviceType: 'UNKNOWN',
  thumbnail:
    'UNKNOWN',
  streamingUrl:
    'UNKNOWN',
  _pubDateISO: '2022-11-30T03:40:54-08:00',
  _airDateISO: '2021-11-14T17:00:00-08:00',
  playerLocUrl:
    'UNKNOWN',
  subscriptionLevel: 'UNKNOWN',
  mediaAvailableDate: '2021-12-31T21:01:00-0800',
  mediaAvailableDateEpoch: 1641013260000,
  closedCaptionUrl:
    'UNKNOWN',
  cmsAccountId: 'dJ5BDC',
  appleWatchListShowKey: 'UNKNOWN',
  isProtected: true,
  mediaType: 'Full Episode',
  excludeNielsenTracking: false,
  vTag: 'UNKNOWN',
  endCreditsChapterTime: '00:56:30',
  thumbnailSet: {
    '0': {
      height: 1080,
      width: 1920,
      assetType: 'Thumbnail',
      url: 'https://thumbnails.cbsig.net/CBS_Production_Entertainment_VMS/2021/10/30/1969322051967/Yellowjackets_101_1002640_1920x1080.jpg',
    },
  },
  downloadCountrySet: {
    '0': {
      code: 'GB',
      downloadable: true,
    },
    '1': {
      code: 'CL',
      downloadable: true,
    },
    '2': {
      code: 'SV',
      downloadable: true,
    },
    '3': {
      code: 'AT',
      downloadable: true,
    },
    '4': {
      code: 'VE',
      downloadable: true,
    },
    '5': {
      code: 'UY',
      downloadable: true,
    },
    '6': {
      code: 'JE',
      downloadable: true,
    },
    '7': {
      code: 'CH',
      downloadable: true,
    },
    '8': {
      code: 'NI',
      downloadable: true,
    },
    '9': {
      code: 'PY',
      downloadable: true,
    },
    '10': {
      code: 'PE',
      downloadable: true,
    },
    '11': {
      code: 'CO',
      downloadable: true,
    },
    '12': {
      code: 'IE',
      downloadable: true,
    },
    '13': {
      code: 'AU',
      downloadable: true,
    },
    '14': {
      code: 'GG',
      downloadable: true,
    },
    '15': {
      code: 'DO',
      downloadable: true,
    },
    '16': {
      code: 'AR',
      downloadable: true,
    },
    '17': {
      code: 'GT',
      downloadable: true,
    },
    '18': {
      code: 'PA',
      downloadable: true,
    },
    '19': {
      code: 'BO',
      downloadable: true,
    },
    '20': {
      code: 'DE',
      downloadable: true,
    },
    '21': {
      code: 'IM',
      downloadable: true,
    },
    '22': {
      code: 'MX',
      downloadable: true,
    },
    '23': {
      code: 'HN',
      downloadable: true,
    },
    '24': {
      code: 'EC',
      downloadable: true,
    },
    '25': {
      code: 'BR',
      downloadable: true,
    },
    '26': {
      code: 'CR',
      downloadable: true,
    },
  },
  regionalRatings: {
    '0': {
      region: 'GB',
      rating: '18+',
      secondaryDescriptors:
        'strong language, moderate sexual references, strong horror & gore, sexual nudity, moderate drugs use',
      ratingIcon: 'files/cbs_page_attribute/18_ukrating_0.png',
    },
  },
  premiumFeatures: {
    '0': '5.1',
  },
  availableForProfileTypes: {
    '0': 'ADULT',
  },
  playbackEvents: {
    endCreditChapterTimeMs: 3390000,
  },
  copyright: 'CBS, Inc.',
  addOns: {},
  isContentAccessibleInCAN: true,
  brandSlug: 'showtime',
  thumbnailSheetSet: {
    '0': {
      height: 126,
      width: 50624,
      assetType: 'ThumbSheet',
      url: 'https://thumbnails.cbsig.net/CBS_Production_Entertainment_VMS/2021/10/30/1969322051967/Yellowjackets_101_Clean_HF063190__1003970_vr_cs_224x126_15_226.jpg',
    },
  },
  __FOR_TRACKING_ONLY_MEDIA_ID: 1972470851671,
  appId: 'PPLUS-SKY-STB v1.3.0-alpha.11',
  brandPlatformId: 'pplusintl_ott_sky',
  deviceId: 'ee6723b8-7ab3-462c-8d93-dbf61227998e',
  deviceName: 'Unknown',
  osVersion: 'Unknown',
  resolution: '1920x1080',
  siteEdition: 'en|US',
  userAgent:
    'Mozilla/5.0 (X11; Linux armv7l) AppleWebKit/537.36 (KHTML, like Gecko) QtWebEngine/5.9.7 Chrome/56.0.2924.122 Safari/537.36 Sky_STB_BC7445_2018/1.0.0 (Sky, ES140UK, )',
  userRegId: 21010761,
  userStatus: 'sb|14',
  userType: 'SUBSCRIBER',
  userProfileId: 19994,
  userProfileCategory: 'ADULT',
  userProfileMaster: true,
  'cm.ssf': 0,
  userProfilePic: 'a31ec9cf-fc60-4cbf-8c21-9655497a2053',
  userProfilePicPath: 'files/profile/GIANT_CYCLOPS_0.png',
  clientTimestamp: '2023-02-21T13:06:32.336Z',
  screenName: '/shows/Yellowjackets/',
  pageType: 'video',
  targetType: 'video',
  pageUrl: '/player/js2eZyDO_1hhBdPekGwBQ97mUWUGZu7x',
  appName: 'PPINTL_Sky',
  appVersion: '1.3.0-alpha.11',
  contentBrand: 'Showtime',
  contentType: 'full episode',
  customDrmType: 'Widevine',
  deviceBrand: 'SKY',
  deviceCategory: 'STB',
  deviceVersion: 'Unknown',
  osName: 'Linux',
  deviceManufacturer: 'Sky',
  drmEnabled: true,
  drmName: 'Widevine',
  mediaSvodContentType: 'PAID',
  targetURL:
    'shows/yellowjackets/video/js2eZyDO_1hhBdPekGwBQ97mUWUGZu7x/yellowjackets-pilot',
  videoTitle: 'Yellowjackets - Pilot',
  playerName: '@cbsinteractive/avia-js',
  ctaText: 'resume',
  usesSsai: false,
  showDayPart: '1991811139703',
  mediaResume: 'true',
  episodeNumber: '1',
  episodeSeason: '1',
  episodeTitle: 'Pilot',
  mediaContentType: 'vod:fullepisodes',
  rowHeaderTitle: 'show hero',
  seasonNumber: '1',
  showAirDate: 1636938000000,
  showEpisodeId: 'js2eZyDO_1hhBdPekGwBQ97mUWUGZu7x',
  showEpisodeLabel: 'Pilot',
  showEpisodeNumber: '1',
  showEpisodeTitle: 'Yellowjackets - Pilot',
  showGenre: 'Drama',
  showSeasonNumber: '1',
  showSectionTitle: 'home',
  showSeriesId: 61457230,
  showSeriesTitle: 'Yellowjackets',
  showTitle: 'Yellowjackets',
  pageViewGuid: '16370fc8-ba4c-450c-988c-1b3d04bab26d',
  playerVersion: '2.10.1',
  playerId: '693e2c4c',
  userCountry: 'us',
  isMobile: false,
  isPaidContent: false,
  isAffiliateFeed: false,
  isPlaylist: false,
  isFullscreen: false,
  systemDevice: 'Desktop',
  screenSize: '1920x1080',
  initTime: 1676984792373,
  durationMs: 3400,
  cdn: 'Akamai',
  renditionWidth: 1920,
  renditionHeight: 1080,
  renditionBitrate: 5700717,
  renditionCodec: 'avc1.640028, mp4a.40.2',
  drmType: 'none',
};
