const KEY_EVENT_MAP: { [key: string]: string } = {
  ArrowLeft: 'left',
  ArrowRight: 'right',
  ArrowUp: 'up',
  ArrowDown: 'down',
  Enter: 'ok',
  Escape: 'back',
  Backspace: 'back',
};

export type KeyEventHandlers = {
  left?: (event: KeyboardEvent) => void;
  right?: (event: KeyboardEvent) => void;
  up?: (event: KeyboardEvent) => void;
  down?: (event: KeyboardEvent) => void;
  ok?: (event: KeyboardEvent) => void;
  back?: (event: KeyboardEvent) => void;
};

function onKeyDown(event: KeyboardEvent, keyEventHandlers: KeyEventHandlers) {
  const { key } = event;

  keyEventHandlers?.[KEY_EVENT_MAP[key] as keyof KeyEventHandlers]?.(event);
}

export const KEYDOWN_EVENT = 'keydown';

export default onKeyDown;
