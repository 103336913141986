import type {
  PluginConfigInterface,
  ResourceConfigurationInterface,
} from '@cbsinteractive/avia-js';

export const loadDynamicPlugins = (
  resource: Partial<ResourceConfigurationInterface>,
): Promise<PluginConfigInterface[]> => {
  const modules = [
    {
      shouldLoad: resource.metadata?.isLive,
      initPlugin: () =>
        import('@cbsinteractive/avia-js-hlsjs').then(({ hlsjs }) => hlsjs()),
    },
    {
      shouldLoad: !resource.metadata?.isLive,
      initPlugin: () =>
        import('@cbsinteractive/avia-js-shaka').then(({ shaka }) => shaka()),
    },
    {
      shouldLoad: resource.ad,
      initPlugin: () =>
        import('@cbsinteractive/avia-js-gam').then(({ gam }) =>
          gam({ debug: true }),
        ),
    },
  ];

  return Promise.all(
    modules
      .map(({ shouldLoad, initPlugin }) => shouldLoad && initPlugin())
      .filter(Boolean),
  ) as Promise<PluginConfigInterface[]>; // TODO: Work on handling types with empty module array.
};
