import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'preact/compat';

import {
  AsyncQueue,
  HookContextInterface,
  PlayerOptionsInterface,
  PluginConfigInterface,
  PluginInterface,
  ResourceConfigurationInterface,
  ResponseInterface,
  VideoPlayerInterface,
  createVideoPlayer,
  LogLevel,
  PlayerEvent,
  PlayerHookType,
  queue,
  removeVideoPlayer,
  Util,
} from '@cbsinteractive/avia-js';
import { loadDynamicPlugins } from './helpers';

export * from '@cbsinteractive/avia-js';
export interface ErrorInterface {
  message: string;
  code: string;
}

export type AviaAttributes = Omit<
  React.JSX.HTMLAttributes<HTMLDivElement>,
  'onError' | 'resource'
>;

export interface AviaProps extends AviaAttributes {
  options: Partial<PlayerOptionsInterface>;
  resource: Partial<ResourceConfigurationInterface>;
  debug?: boolean;
  onPlayerChange?: (player: VideoPlayerInterface) => void;
  onError?: (error: ErrorInterface) => void;
}

export type AviaRef = VideoPlayerInterface | null | undefined;
type PlayerContext = PluginConfigInterface<PluginInterface>;

const createPlayerStep =
  (
    handler: (
      context: PlayerContext,
    ) => Promise<void | PlayerContext> | undefined,
  ) =>
  async (context: PlayerContext): Promise<PlayerContext> => {
    const newContext = await handler(context);

    return newContext ?? context;
  };

export const Avia = forwardRef<AviaRef, AviaProps>((props, ref) => {
  const {
    options,
    resource,
    debug = false,
    onError,
    onPlayerChange,
    children,
    ...attrs
  } = props;

  // player state and refs
  const id = useMemo(() => options?.id || Util.uid8(), [options?.id]);
  const container = useRef<HTMLDivElement>(null);

  const [player, setPlayer] = useState<VideoPlayerInterface | null>(null);

  useImperativeHandle<AviaRef, AviaRef>(ref, () => player);

  // prep handlers
  const errorHandlerRef = useRef((error: ErrorInterface) => onError?.(error));
  const playerHandlerRef = useRef((player: VideoPlayerInterface) =>
    onPlayerChange?.(player),
  );

  // task queue
  const queueRef = useRef<AsyncQueue<PlayerContext>>();

  // create player
  useEffect(() => {
    if (!options) {
      return undefined;
    }

    const eRef = errorHandlerRef.current;
    const prev = queueRef.current;

    const cancelPreviousPlayerRoutine = createPlayerStep(() => prev?.cancel());
    const destroyPreviousPlayer = createPlayerStep(() => removeVideoPlayer(id));
    const createPlayerOptions = createPlayerStep((context: PlayerContext) =>
      Promise.resolve({
        logLevel: debug ? LogLevel.DEBUG : LogLevel.OFF,
        ...options,
        plugins: { ...context, ...(options?.plugins || {}) },
        id,
        container: container.current,
      }),
    );
    const createPlayer = createPlayerStep(async (context: PlayerContext) => {
      // create the player instance
      const player = await createVideoPlayer(context as PlayerOptionsInterface);

      // removes AdaptationSet with contentType=image
      //temporary fix for https://paramountplus.atlassian.net/browse/PPISTBX-387
      player.registerHook(
        PlayerHookType.RESPONSE,
        (context: HookContextInterface<ResponseInterface>): void => {
          const response = context.value;

          if (!response.url.includes('.mpd')) {
            return;
          }

          const xml = new DOMParser().parseFromString(
            response.data,
            'text/xml',
          );
          const roles = xml.querySelectorAll(
            'AdaptationSet[contentType=image]',
          );

          roles.forEach((role) => role?.parentElement?.removeChild(role));

          response.data = new XMLSerializer().serializeToString(xml);

          const videoAdaps = xml.querySelectorAll(
            'AdaptationSet[contentType="video"]',
          );

          let codecs = {};

          videoAdaps.forEach((adap) => {
            const reps = adap.querySelectorAll('Representation');

            const currentCodecs = [...reps].reduce((acc, rep) => {
              // @ts-ignore
              const { bandwidth, codecs, width, height } = rep.attributes;

              const codec = codecs.nodeValue;
              const resolution = `${width.nodeValue}x${height.nodeValue}`;

              return {
                ...acc,
                [codec]: {
                  isTypeSupported: MediaSource.isTypeSupported(
                    `video/mp4; codecs=${codec}`,
                  ),
                  resolution,
                  bandwidth: Number(bandwidth.nodeValue),
                },
              };
            }, {});

            codecs = { ...codecs, ...currentCodecs };
          });

          console.table(codecs);
        },
      );

      player.on(PlayerEvent.ERROR, (event) => eRef(event.detail.error));
      setPlayer(player);
    });

    queueRef.current = queue([
      cancelPreviousPlayerRoutine,
      destroyPreviousPlayer,
      createPlayerOptions,
      createPlayer,
    ]);

    queueRef.current.run();

    return () => {
      queueRef.current?.cancel();
      removeVideoPlayer(id).catch(eRef);
    };
  }, [resource, options, debug, id]);

  //  play resource
  useEffect(() => {
    if (!player) {
      return undefined;
    }

    playerHandlerRef.current(player);

    const createResourcePlugins = createPlayerStep(async () => {
      const plugins = await loadDynamicPlugins(resource);

      return player.registerPlugins(plugins);
    });

    const attachResource = createPlayerStep(async () => {
      if (resource) {
        await player.attachResource(resource);
      }
    });

    queueRef.current = queue([createResourcePlugins, attachResource]);

    queueRef.current.run();

    return () => {
      queueRef.current?.cancel();
    };
  }, [player, resource, debug]);

  return (
    <div ref={container} {...attrs}>
      {children}
    </div>
  );
});
