import { algo } from 'crypto-js/core';
import hex from 'crypto-js/enc-hex';
import 'crypto-js/aes';
import WordArray from 'crypto-js/lib-typedarrays';

import config from '##/config.json';

const IV_LEN = 16;
const SALT = '302a6a0d70a7e9b967f91d39fef3e387816e3095925ae4537bce96063311f9c5';
const API_URL = '/api';

export const DRM_URLS = {
  widevine: 'https://cbsi.stage.ott.irdeto.com/widevine/getlicense',
  playready: 'https://cbsi.stage.ott.irdeto.com/playready/rightsmanager.asmx',
  aes: 'https://cbsi.stage.ott.irdeto.com',
};

class Auth {
  convertWordArrayToUint8Array = (wordArray: WordArray): Uint8Array => {
    const len = wordArray.words.length;

    const u8Array = new Uint8Array(len << 2);
    let offset = 0;

    for (let i = 0; i < len; i += 1) {
      const word = wordArray.words[i];

      u8Array[offset] = word >> 24;
      offset += 1;
      u8Array[offset] = (word >> 16) & 0xff;
      offset += 1;
      u8Array[offset] = (word >> 8) & 0xff;
      offset += 1;
      u8Array[offset] = word & 0xff;
      offset += 1;
    }

    return u8Array;
  };

  convertUint8ArrayToWordArray = (u8Array: Uint8Array): WordArray => {
    const words = [];
    const len = u8Array.length;

    for (let i = 0; i < len; i += 4) {
      words.push(
        (u8Array[i] << 24) |
          (u8Array[i + 1] << 16) |
          (u8Array[i + 2] << 8) |
          u8Array[i + 3],
      );
    }

    return {
      sigBytes: words.length * 4,
      words,
    } as WordArray;
  };

  generateIV = (): Uint8Array => {
    const ivWordArray = WordArray.random(IV_LEN);

    return this.convertWordArrayToUint8Array(ivWordArray);
  };

  shortToByteArray = (shortInt: number): Uint8Array => {
    const bytes = new Uint8Array(2);

    bytes[0] = (shortInt >> 8) & 0xff;
    bytes[1] = shortInt & 0xff;

    return bytes;
  };

  getAccessToken = (): string => {
    const authToken = `${config.authTokenPrefix}|${config.appSecretFoxtel}`;
    const iv = this.generateIV();
    const cipher = algo.AES.create(
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      algo.AES._ENC_XFORM_MODE,
      hex.parse(SALT),
      { iv: this.convertUint8ArrayToWordArray(iv) },
    );

    const cipherText = cipher.finalize(authToken);
    const cipherTextBytes = this.convertWordArrayToUint8Array(cipherText);
    const output = new Uint8Array(iv.length + cipherTextBytes.length + 2);
    const ivLengthBytes = this.shortToByteArray(iv.length);
    let outputIndex = 2;
    let i;

    [output[0], output[1]] = ivLengthBytes;

    for (i = 0; i < iv.length; i += 1) {
      output[outputIndex] = iv[i];
      outputIndex += 1;
    }

    for (i = 0; i < cipherTextBytes.length; i += 1) {
      output[outputIndex] = cipherTextBytes[i];
      outputIndex += 1;
    }

    return window.btoa(
      String.fromCharCode.apply(null, output as unknown as number[]),
    );
  };

  login = async (): Promise<void> => {
    const response = await fetch(
      `${API_URL}/apps-api/v2.0/sky/auth/login.json?at=${encodeURIComponent(
        this.getAccessToken(),
      )}`,
      {
        // @ts-ignore
        body: `j_username=${config[config.env].username}&j_password=${
          // @ts-ignore
          config[config.env].password
        }`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'post',
        credentials: 'include',
      },
    );

    const respJson = await response.json();

    if (!respJson.success) {
      throw respJson;
    }

    return;
  };

  createDrmUrl = (
    drmParams: URLSearchParams,
    protectionType: 'widevine' | 'playready',
  ) => {
    const drmUrl = new URL(DRM_URLS[protectionType]);

    drmUrl.search = drmParams.toString();

    return drmUrl.href;
  };

  getSessionTokenBody = async () => {
    try {
      const sessionTokenResponse = await fetch(
        // eslint-disable-next-line max-len
        `${API_URL}/apps-api/v3.0/sky/irdeto-control/session-token.json?at=${encodeURIComponent(
          this.getAccessToken(),
        )}`,
        { credentials: 'include' },
      );
      const sessionTokenResponseBody = await sessionTokenResponse.json();

      if (
        !sessionTokenResponseBody ||
        !sessionTokenResponseBody.ls_session ||
        !sessionTokenResponseBody.url
      ) {
        console.error(
          'DRM FAILED',
          sessionTokenResponse,
          sessionTokenResponseBody,
        );

        return;
      }

      console.log('[DRM] Session token:', sessionTokenResponseBody);

      return sessionTokenResponseBody;
    } catch (e) {
      console.error(e);

      return {};
    }
  };

  getDrmConfig = async (
    contentId: string,
    protectionType: 'widevine' | 'playready',
  ) => {
    try {
      const sessionTokenResponseBody = await this.getSessionTokenBody();

      const rawDrmParams: Record<string, string> = {
        CrmId: 'cbsi',
        AccountId: 'cbsi',
        SubContentType: 'Default',
        ContentId: contentId,
        ls_session: sessionTokenResponseBody?.ls_session,
      };
      const drmParams = new URLSearchParams();

      Object.keys(rawDrmParams).forEach((key: string) => {
        drmParams.set(key, rawDrmParams[key]);
      });

      return this.createDrmUrl(drmParams, protectionType);
    } catch (e) {
      console.error(e);
    }
  };
}

export default new Auth();
