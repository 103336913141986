import type {
  AudioTrackInterface,
  QualityInterface,
  TextTrackInterface,
} from '@cbsinteractive/avia-js';
import cls from 'classnames';
import styles from './playerMetrics.module.scss';
import useSettings from '##/hooks/useSettings';

type PlayerMetricsProps = {
  audioTrack?: AudioTrackInterface;
  quality?: QualityInterface;
  subtitle?: TextTrackInterface;
  logs?: string[];
  className?: string;
  isVisible: boolean;
};

const formatAudioTrack = (audioTrack?: AudioTrackInterface) => {
  if (audioTrack) {
    return `${audioTrack?.language} (${audioTrack?.codec}, ${audioTrack?.type})`;
  }

  return '-';
};

const formatQuality = (quality?: QualityInterface) => {
  if (quality) {
    return `${quality.category?.join(' ')} (${quality.codec})`;
  }

  return '-';
};

const PlayerMetrics = ({
  className,
  audioTrack,
  quality,
  subtitle,
  logs,
  isVisible,
}: PlayerMetricsProps) => {
  const { settings } = useSettings();

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cls(styles.container, className)}>
      <div>
        <div>
          <span className={styles.bold}>Current quality: </span>
          <span>{formatQuality(quality)}</span>
        </div>
        <div>
          <span className={styles.bold}>Current audio track: </span>
          <span>{formatAudioTrack(audioTrack)}</span>
        </div>
        <div>
          <span className={styles.bold}>Current subtitle: </span>
          <span>{subtitle?.language ?? '-'}</span>
        </div>
        <div>
          <span className={styles.bold}>Preferred video codec: </span>
          <span>
            {(settings.preferredVideoCodec as string[])?.join(', ') || '-'}
          </span>
        </div>
        <div>
          <span className={styles.bold}>Preferred audio codec: </span>
          <span>
            {(settings.preferredAudioCodec as string[])?.join(', ') || '-'}
          </span>
        </div>
        <div>
          <span className={styles.bold}>DRM: </span>
          <span>{settings.drmSystem}</span>
        </div>
      </div>
      {!!logs?.length && (
        <div className={styles.logs}>
          <div className={styles.bold}>Error Logs</div>
          {logs?.map((log, idx) => (
            <div key={`errorlog_${idx}`}>{log}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayerMetrics;
