import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import cls from 'classnames';
import type { VideoPlayerInterface } from '@cbsinteractive/avia-js';
import nav from '##/navigation';
import type { SettingId } from '##/types';
import styles from './settingsList.module.scss';
import type { DRMOptions } from '../Player/Player';

interface Props {
  setting: { id: SettingId; text: string };
  player?: VideoPlayerInterface;
  setDrmType: (drm: 'widevine' | 'playready') => void;
  drmType: string;
  setDrmOptions: StateUpdater<DRMOptions>;
  drmOptions: DRMOptions;
}

let lastFocus = 'list';

const SettingsList = ({
  setting,
  player,
  setDrmType,
  drmType,
  setDrmOptions,
  drmOptions,
}: Props) => {
  const [currentFocus, setCurrentFocus] = useState(0);

  const items = useMemo(() => {
    if (setting.id === 'quality') {
      return player?.qualities.map(({ index, category, bitrate }) => ({
        id: `${index}`,
        text: category?.join(' '),
        enabled: player?.quality?.bitrate === bitrate,
        action: () => {
          player.autoQualitySwitching = false;
          player.bitrate = bitrate;
        },
      }));
    } else if (setting.id === 'audio') {
      return player?.audioTracks.map(({ id, codec, language, type }) => ({
        id,
        text: `${language} (${codec}, ${type})`,
        enabled: id === player?.audioTrack?.id,
        action: () => {
          player.selectAudioLanguage(language);
        },
      }));
    } else if (setting.id === 'subtitles') {
      return player?.textTracks.map(({ id, label, language }) => ({
        id,
        text: label,
        enabled: id === player?.textTrack?.id,
        action: () => {
          player.selectTextLanguage(language);
        },
      }));
    } else if (setting.id === 'drm_options') {
      return [
        {
          id: 'none',
          text: 'No preference',
          enabled: !drmOptions.audioRobustness && !drmOptions.videoRobustness,
          action: () => {
            setDrmOptions({});
          },
        },
        {
          id: 'l1_audio_video',
          text: 'L1 Video, Audio',
          enabled:
            drmOptions.videoRobustness === 'HW_SECURE_ALL' &&
            drmOptions.audioRobustness === 'HW_SECURE_ALL',
          action: () => {
            setDrmOptions({
              audioRobustness: 'HW_SECURE_ALL',
              videoRobustness: 'HW_SECURE_ALL',
            });
          },
        },
        {
          id: 'l1_audio_video_decode',
          text: 'L1 Video, Audio (decode)',
          enabled: drmOptions.videoRobustness === 'HW_SECURE_DECODE',
          action: () => {
            setDrmOptions({
              audioRobustness: 'HW_SECURE_DECODE',
              videoRobustness: 'HW_SECURE_DECODE',
            });
          },
        },
        {
          id: 'l1_video_l2_audio',
          text: 'L1 Video, L2 Audio',
          enabled:
            drmOptions.videoRobustness === 'HW_SECURE_ALL' &&
            drmOptions.audioRobustness === 'HW_SECURE_CRYPTO',
          action: () => {
            setDrmOptions({
              videoRobustness: 'HW_SECURE_ALL',
              audioRobustness: 'HW_SECURE_CRYPTO',
            });
          },
        },
        {
          id: 'l3_video_l3_audio',
          text: 'L3 Video, Audio',
          enabled: drmOptions.videoRobustness === 'SW_SECURE_CRYPTO',
          action: () => {
            setDrmOptions({
              audioRobustness: 'SW_SECURE_CRYPTO',
              videoRobustness: 'SW_SECURE_CRYPTO',
            });
          },
        },
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setting,
    player,
    player?.textTrack,
    player?.audioTrack,
    player?.quality,
    drmType,
    setDrmType,
  ]);

  useEffect(() => {
    if (items?.length) {
      nav.registerTree({
        id: 'list',
        orientation: 'vertical',
        children: items?.map((item, index) => ({
          id: `list_item_${item.id}`,
          isFocusable: true,
          onFocus: () => {
            lastFocus = `list_item_${item.id}`;
            setCurrentFocus(index);
          },
          onSelect: () => {
            item.action();
          },
        })),
      });

      nav.assignFocus(lastFocus);

      return () => {
        nav.unregisterNode('list');
      };
    }
  }, [items]);

  useEffect(() => {
    return () => {
      lastFocus = 'list';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{setting.text}</div>
      <div className={styles.list}>
        {items?.map((item, index) => (
          <div
            key={item.id}
            className={cls(styles.item, {
              [styles.focused]: currentFocus === index,
              [styles.enabled]: item.enabled,
            })}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsList;
