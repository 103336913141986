import { useEffect, useState } from 'preact/hooks';
import { PlayerEvent, VideoPlayerInterface } from '@cbsinteractive/avia-js';

type PlaybackStateReturn = {
  playbackState: string;
};

const usePlaybackState = (
  player: VideoPlayerInterface | undefined,
): PlaybackStateReturn => {
  const [playbackState, setPlaybackState] = useState<string>('');

  useEffect(() => {
    if (!player) {
      return;
    }

    const handlePlaybackStateChange = () =>
      setPlaybackState(player?.playbackState);

    player.on(PlayerEvent.PLAYBACK_STATE_CHANGE, handlePlaybackStateChange);

    return () => {
      player.off(PlayerEvent.PLAYBACK_STATE_CHANGE, handlePlaybackStateChange);
    };
  }, [player]);

  return { playbackState };
};

export default usePlaybackState;
