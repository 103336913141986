import { useCallback } from 'preact/hooks';
import {
  AudioTrackInterface,
  PlaybackState,
  QualityInterface,
  TextTrackInterface,
  VideoPlayerInterface,
} from '@cbsinteractive/avia-js';
import type { Maybe, PlayerControls } from '##/types';

const usePlayerControls = (
  player: VideoPlayerInterface | undefined,
): PlayerControls => {
  const playPause = useCallback(() => {
    if (player?.playbackState === PlaybackState.PLAYING) {
      player.pause();
    } else if (player?.playbackState === PlaybackState.PAUSED) {
      player.play();
    }
  }, [player]);

  const fastForward = useCallback(() => {
    player?.seek(player?.contentTime + 30);
  }, [player]);

  const rewind = useCallback(() => {
    player?.seek(player?.contentTime - 30);
  }, [player]);

  const changeQuality = useCallback(
    (quality: Maybe<QualityInterface>) => {
      if (!player || !quality) {
        return;
      }

      player.autoQualitySwitching = false;
      player.bitrate = quality.bitrate;
    },
    [player],
  );

  const changeAudio = useCallback(
    (audio: Maybe<AudioTrackInterface>) => {
      if (!player || !audio) {
        return;
      }

      player.selectAudioLanguage(audio?.language);
    },
    [player],
  );

  const changeSubtitle = useCallback(
    (sub: Maybe<TextTrackInterface>) => {
      if (!player || !sub) {
        return;
      }

      player.selectTextLanguage(sub.language, sub.kind as TextTrackKind);
    },
    [player],
  );

  const getQuality = useCallback(() => player?.quality, [player]);
  const getQualities = useCallback(() => player?.qualities, [player]);

  const getAudio = useCallback(() => player?.audioTrack, [player]);
  const getAudios = useCallback(() => player?.audioTracks, [player]);

  const getSubtitle = useCallback(() => player?.textTrack, [player]);
  const getSubtitles = useCallback(() => player?.textTracks, [player]);

  return {
    playPause,
    fastForward,
    rewind,
    changeQuality,
    changeAudio,
    changeSubtitle,
    getQuality,
    getAudio,
    getSubtitle,
    getQualities,
    getAudios,
    getSubtitles,
  };
};

export default usePlayerControls;
