import type { Asset } from '##/types';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import configJson from '##/config.json';
import useAuth from './useAuth';

type UseConfigReturn = {
  config: Record<string, unknown>;
  assets: Asset[];
};

class Assets {
  constructor() {
    this.assets = [];
  }

  async fetchAssets() {
    if (this.assets.length) {
      return;
    }

    const response = await fetch('/assets/');
    this.assets = await response.json();
  }

  getAssets(loginState: string) {
    return loginState === 'failed'
      ? this.assets.filter((asset) => !asset.id)
      : this.assets;
  }

  assets: Asset[];
}

const assets = new Assets();

const useConfig = (): UseConfigReturn => {
  const loginState = useAuth();
  const config = useRef(configJson);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    assets.fetchAssets().then(() => setAssetsLoaded(true));
  }, [loginState]);

  const assetsMemo = useMemo(
    () => assets.getAssets(loginState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginState, assetsLoaded],
  );

  return { config: config.current, assets: assetsMemo };
};

export default useConfig;
